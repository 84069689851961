<template>
  <div>
    <s-drawer
      :no-header="true"
      type="full"
      v-model:visible="isShow"
      direction="rtl"
      :append-to-body="true"
    >
      <div
        v-cloak
        class="mshe-z-transform1 transform-fixed mshe-bg-container sizeChart-drawer"
        :class="{'sizeGuidPlanA-drawer': sizeGuidPlanA}"
      >
        <section>
          <header class="sizeChart-drawer__header">
            <i
              class="suiiconfont sui_icon_nav_back_24px j-clear-history-hash"
              @click="isShow = false"
            ></i>
            {{ language.SHEIN_KEY_PWA_15005 }}
          </header>
        </section>
        <section class="sizeChart-drawer__content">
          <div
            class="common-detail"
          >
            <div class="common-detail__left">
              {{ language.SHEIN_KEY_PWA_17557 }}
            </div>
            <div class="common-detail__right">
              <div
                v-if="localsize && localsize.size_rule_list && hasOneLocalColumn"
                class="sg-size-local"
                @click="localSizeSelectHandle(true)"
              >
                <span>
                  <template v-if="!localsize.size_rule_list[selectLocalsize]">
                    {{ language.SHEIN_KEY_PWA_23344 }}
                  </template>
                  <template v-else>
                    {{ selectLocalsize }} {{ language.SHEIN_KEY_PWA_15701 }}
                  </template>
                  <i class="suiiconfont sui_icon_more_down2_12px"></i>
                </span>
              </div>
              <ul class="sg-size-newTitle">
                <div
                  class="sg-title-ctn"
                >
                  <template v-if="sizeInfoDes && sizeInfoDes.sizeUnit == '1'">
                    <li
                      :class="{'title-active': unitType=== 'inch'}"
                      :style="{'borderRight':unitType=== 'inch'? '': 'none'}"
                      da-event-click="1-6-1-25"
                      data-unit="IN"
                      @click="changeUnit('inch')"
                    >
                      IN
                    </li>
                    <li
                      :class="{'title-active': unitType=== 'cm'}"
                      :style="{'borderLeft':unitType=== 'cm'? '': 'none'}"
                      da-event-click="1-6-1-25"
                      data-unit="CM"
                      @click="changeUnit('cm')"
                    >
                      CM
                    </li>
                  </template>
                  <template v-else>
                    <li
                      :class="{'title-active': unitType=== 'cm'}"
                      :style="{'borderRight':unitType=== 'inch'? '': 'none'}"
                      da-event-click="1-6-1-25"
                      data-unit="CM"
                      @click="changeUnit('cm')"
                    >
                      CM
                    </li>
                    <li
                      :class="{'title-active': unitType=== 'inch'}"
                      :style="{'borderLeft':unitType=== 'cm'? '': 'none'}"
                      da-event-click="1-6-1-25"
                      data-unit="IN"
                      @click="changeUnit('inch')"
                    >
                      IN
                    </li>
                  </template>
                </div>
              </ul>
            </div>
          </div>
          <div
            v-if="showFitType || showFabric"
            class="fit-fabric"
          >
            <div :class="{'fit-fabric__planA': sizeGuidPlanA}">
              <div
                v-if="showFitType"
                ref="div1"
                class="fit-fabric__title"
              >
                {{ language.SHEIN_KEY_PWA_17611 }}
              </div>
              <div
                v-if="showFitType"
                class="fit-fabric__dec"
              >
                <div
                  v-for="(item,index) in fitType"
                  :key="index"
                  :class="{'fit-fabric__right':fitType.length-1===index,
                           'fit-fabric__center':fitType.length-1!==index&&index!==0,
                           'fit-fabric__left': index===0}"
                >
                  <span
                    class="fit-fabric__pos"
                    :class="{'fit-fabric__posR':fitType.length-1===index,
                             'fit-fabric__posC':fitType.length-1!==index&&index!==0,
                             'fit-fabric__select':showFitType.key===item.key}"
                  >
                    <div
                      v-show="showFitType.key===item.key"
                      class="fit-fabric__icon"
                    ></div>
                  </span>
                  {{ item.value }}
                </div>
              </div>
            </div>
            <div :class="{'fit-fabric__planA': sizeGuidPlanA}">
              <div
                v-if="showFabric"
                ref="div2"
                class="fit-fabric__title"
              >
                {{ language.SHEIN_KEY_PWA_18059 }}
              </div>
              <div
                v-if="showFabric"
                class="fit-fabric__dec"
              >
                <div
                  v-for="(item,index) in fabric"
                  :key="index"
                  :class="{'fit-fabric__right':fabric.length-1===index,
                           'fit-fabric__center':fabric.length-1!==index&&index!==0,
                           'fit-fabric__left': index===0}"
                >
                  <span
                    class="fit-fabric__pos"
                    :class="{'fit-fabric__posR':fabric.length-1===index,
                             'fit-fabric__posC':fabric.length-1!==index&&index!==0,
                             'fit-fabric__select':showFabric.key===item.key }"
                  >
                    <div
                      v-show="showFabric.key===item.key"
                      class="fit-fabric__icon"
                    ></div>
                  </span>
                  {{ item.value }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-show="tableHeadBase.length && tableBodyBase.result.length"
            class="size-table-outer"
          >
            <div
              v-if="tableTitle"
              class="sg-table-t"
            >
              {{ tableTitle }}
            </div>
            <div
              v-else
              class="sg-table__title"
            >
              <div
                class="sg-table__title-s"
                :class="{active: tableStatus == 'normal', sortAfter: initTableStatus == 'base'}"
                da-event-click="1-6-1-46"
                data-type="product"
                @click="changeTableStatus('normal')"
              >
                {{ language.SHEIN_KEY_PWA_17563 }}
              </div>
              <div
                class="sg-table__title-s"
                :class="{active: tableStatus == 'base', sortAfter: initTableStatus == 'normal'}"
                da-event-click="1-6-1-46"
                data-type="body"
                @click="changeTableStatus('base')"
              >
                {{ language.SHEIN_KEY_PWA_17561 }}
              </div>
            </div>
            <ul
              v-if="multiPartList.length > 1"
              v-show="tableStatus == 'normal'"
              class="sg-table__part"
            >
              <div
                class="sg-table__part-wrap"
              >
                <li
                  v-for="item in multiPartList"
                  :key="item?.code"
                  :class="{'part-active': currMultiPart?.code === item?.code}"
                  @click="selectMultiPart = item"
                >
                  {{ item.name }}
                </li>
              </div>
            </ul>
            <div
              id="sg-table-ctn-1"
              class="sg-table-ctn scrollbar-inner sg-table-side"
              data-type="cm-inh"
            >
              <!-- 尺码表 -->
              <div :style="{width: tableContainerWidth}">
                <div
                  class="sg-table-box"
                  v-if="tableHeadBase && tableHeadBase.length"
                  @scroll="handleScroll"
                >
                  <table class="sg-table-content">
                    <thead>
                      <tr>
                        <template
                          v-for="(item, index) in tableHeadBase"
                        >
                          <th
                            :key="index"
                            :class="['sg-table_td', {'sg-table_td-shadow': index == '0' && showShadow}]"
                            v-if="!(!hasSizeColumn && item.value === headName) && tableBodyBase.nullMap[index]"
                          >
                            {{ item.value }}
                          </th>
                        </template>
                      </tr>
                    </thead>
                    <tbody
                      v-if="tableBodyBase && tableBodyBase.result"
                    >
                      <template
                        v-for="(item, index) in tableBodyBase.result"
                      >
                        <tr
                          :key="index"
                          v-if="item.length === tableHeadBase.length"
                        >
                          <template
                            v-for="(childItem, childIdx) in item"
                          >
                            <td
                              :key="childIdx"
                              :class="['sg-table_td', {'sg-table_td-shadow': childIdx == '0' && showShadow}]"
                              v-if="tableBodyBase.nullMap[childIdx] && childItem"
                            >
                              {{ filterUnit(childItem) }}
                            </td>
                          </template>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="sg-table-notice">
              {{ sizeTipsText }}
            </div>
            <div
              v-show="isJapan"
              class="sg-table-notice"
            >
              {{ language.SHEIN_KEY_PWA_31609 }}
            </div>
          </div>
          <div
            v-if="detail.sizeTemplate && detail.sizeTemplate.description_multi && tableStatus == 'normal'"
            class="measure-guide"
          >
            <div class="measure-guide__header">
              <span>
                {{ language.SHEIN_KEY_PWA_18087 }}
              </span>
              <i
                color="#959595"
                :class="{
                  suiiconfont: true,
                  sui_icon_more_down_16px: true,
                  reverse: showDetail
                }"
                @click="showDetail = !showDetail"
              ></i>
            </div>
            <div v-if="showDetail">
              <div class="measure-guide__pic">
                <div
                  v-if="Array.isArray(detail.sizeTemplate.description_multi)"
                  class="measure-pic-guide"
                >
                  <div
                    v-for="item in detail.sizeTemplate.description_multi"
                    :key="item.name"
                    class="measure-wrap"
                  >
                    <h6><span>{{ item.sort }}. </span>{{ item.name }}</h6>
                    <p>{{ item.description }}</p>
                  </div>
                </div>
                <div
                  v-if="detail.sizeTemplate.image_url"
                  class="measure-pic0"
                >
                  <img :src="detail.sizeTemplate.image_url.replace(/^http:/,'')" />
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="sizeInfoDes.basicAttribute&&sizeInfoDes.basicAttribute.image_url && tableStatus == 'base'"
            class="measure-guide"
          >
            <div class="measure-guide__header">
              <span>{{ language.SHEIN_KEY_PWA_17564 }}</span>
              <i
                color="#959595"
                :class="{
                  suiiconfont: true,
                  sui_icon_more_down_16px: true,
                  reverse: showDetail
                }"
                @click="showDetail = !showDetail"
              ></i>
            </div>
            <div v-if="showDetail">
              <div class="measure-guide__pic">
                <div
                  v-if="Array.isArray(sizeInfoDes.basicAttribute&&sizeInfoDes.basicAttribute.attribute_info)"
                  class="measure-pic-guide"
                >
                  <div
                    v-for="(item,index) in sortAttributeArr"
                    :key="item.name"
                    class="measure-wrap"
                  >
                    <h6><span>{{ index+1 }}</span>. {{ item.name }}</h6>
                    <p>{{ item.desc }}</p>
                  </div>
                </div>
                <div class="measure-pic0">
                  <img :src="sizeInfoDes.basicAttribute.image_url.replace(/^http:/,'')" />
                </div>
              </div>
            </div>
          </div>
          <div 
            v-if="sizeGuidPlanA"
            :class="{'measure-guide__footer':showModel}"
          >
            <!-- <ModelIntro
              :language="language"
              :localsize="localsize"
              :currentLocalCountry="currentLocalCountry"
              :parentCats="parentCats"
              :modelRelativesConfig="modelRelativesConfig"
              :modelContent="modelContent"
              :style="{ paddingTop: '.32rem' }"
              :unitType="unitType"
              @handleShowModel="handleShowModel"
            /> -->
            <NewModelIntro
              :currentLocalCountry="currentLocalCountry"
              :modelContent="modelContent"
              :language="language"
              :style="{ paddingTop: '.32rem' }"
              @handleShowModel="handleShowModel"
            />
          </div>
          <BuyerSizeTable
            v-if="!IS_RW && sizeGuideFit === 'planA' && tspShowBuySizeTable"
            :visible="isShow"
            style="margin-top: 8px;"
            :spu-id="detail.productRelationID"
            :skc="detail.goods_sn"
            :language="language"
            :size-sort-map="sizeSortMap"
            :localSizes="localsize?.size_rule_list"
            :country="country"
            :length-unit="unitType"
            @handleShowTable="handleShowTable"
          />
        </section>
      </div>
    </s-drawer>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue'
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
const { SiteUID, LAZY_IMG_SQUARE, IS_RW } = gbCommonInfo
import { formatSizeInfo, getPrioritySize } from 'public/src/pages/goods_detail_v2/utils/size-info-desc.js'
import { cloneDeep } from 'lodash'
import NewModelIntro from 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/ModelIntro/index.vue'
import SelectLocalSize from 'public/src/pages/components/product/select-local-size/index.js'
import BuyerSizeTable from './components/BuyerSizeTable/index.vue'

export default defineComponent({
  name: 'SizeGuideDrawer',
  components: {
    NewModelIntro,
    BuyerSizeTable,
    SDrawer,
  },
  emits: [
    'update:model-value',
    'changeSizeUnit',
    'changeLocalSize',
  ],
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    tspShowBuySizeTable: {
      type: Boolean,
      default: false
    },
    // status: {
    //   type: Object,
    //   default () {
    //     return {}
    //   }
    // },
    showSizeGuideDrawer: {
      type: Boolean,
      default: false
    },
    language: {
      type: Object,
      default () {
        return {}
      }
    },
    sizeInfoDes: {
      type: Object,
      default () {
        return {}
      }
    },
    sizepriority: {
      type: String,
    },
    sizeGuideFit: {
      type: String,
      default: 'off'
    },
    localsize: {
      type: [Object, Array],
      default () {
        return {} || []
      }
    },
    detail: {
      type: Object,
      default () {
        return {}
      }
    },
    isBodySize: {
      type: Boolean,
      default: false
    },
    // multiDrawer: {
    //   type: Boolean,
    //   default: false
    // },
    appendToBody: {
      type: Boolean,
      default: false
    },
    country: {
      type: String,
      default: ''
    },
    // showSizeGuideFrom: {
    //   type: String,
    //   default: ''
    // },
    isNewSizeLocal: {
      type: Boolean,
      default: false
    },
    skcSaleAttr: {
      type: Array,
      default: () => ([])
    },
    parentCats: {
      type: Object,
      default: () => ({})
    },
    modelRelativesConfig: {
      type: Object,
      default: () => ({})
    },
    modelContent: {
      type: Object,
      default: () => ({})
    },
    currentLocalCountry: {
      type: String,
      default: ''
    },
    sizeGuidPlanA: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      LAZY_IMG_SQUARE,
      IS_RW,
      isShow: false,
      showLocalSize: false,
      unitType: this.sizeInfoDes?.sizeUnit != '1' ? 'cm' : 'inch', // inch cm
      tableStatus: 'normal',
      selectLocalsize: '',
      selectMultiPart: '',
      // 优先级tab信息
      initTableStatus: 'normal',
      showDetail: true,
      showModel: false,
      showShadow: false
    }
  },
  computed: {
    sizeSortMap () {
      if (this.sizeInfoDes?.multiPartFlag === 1) { // 是否多部件商品
        // 取第一个有尺码信息的部件
        const partInfo = this.sizeInfoDes?.multiPartInfo?.find(item => {
          return item.multiPartSizeInfo?.length
        })
        const sizeInfo = partInfo?.multiPartSizeInfo || []
        return this.genSizeSortMap(sizeInfo)
      }else {
        const sizeInfo = this.sizeInfoDes?.sizeInfo || []
        return this.genSizeSortMap(sizeInfo)
      }
    },
    isJapan() {
      return SiteUID === 'mjp'
    },
    goodsId() {
      return this.detail?.goods_id || ''
    },
    sortAttributeArr() {
      const arr = this.sizeInfoDes?.basicAttribute?.attribute_info || []
      return arr.sort((curr, next) => {
        return curr.sort - next.sort
      })
    },
    sizeInfoStrFilterField () {
      return ['size', 'attr_id', 'attr_name', 'attr_value_id', 'attr_value_name', 'attr_value_name_en']
    },
    fabric () {
      if (!Object.keys(this.language).length) return []
      return [
        {
          key: 'Non-Stretch',
          value: this.language.SHEIN_KEY_PWA_18060
        },
        {
          key: 'Slight Stretch',
          value: this.language.SHEIN_KEY_PWA_18061
        },
        {
          key: 'Medium Stretch',
          value: this.language.SHEIN_KEY_PWA_18062
        },
        {
          key: 'High Stretch',
          value: this.language.SHEIN_KEY_PWA_18063
        },
      ]
    },
    fitType() {
      if (!Object.keys(this.language).length) return []
      return [
        {
          key: 'Skinny',
          value: this.language.SHEIN_KEY_PWA_17558
        },
        {
          key: 'Slim Fit',
          value: ''
        },
        {
          key: 'Regular Fit',
          value: this.language.SHEIN_KEY_PWA_17559
        },
        {
          key: 'Loose',
          value: ''
        },
        {
          key: 'Oversized',
          value: this.language.SHEIN_KEY_PWA_17560
        } 
      ]
    },
    showFitType () {
      const multiPartProductDetails = this.detail.isMultiPartProduct ?
        (this.detail.productDetails || []).concat(this.detail.multiPartInfo.reduce(function (prev, item) {
          prev.push(...(item.attributeList || []))
          return prev
        }, [])) :
        (this.detail.productDetails || [])
      const find = multiPartProductDetails.find(i => i.attr_name_en === 'Fit Type')
      const findIt = find && find['attr_value_en']
      return this.fitType.find(i => i.key === findIt)
    },
    showFabric () {
      const multiPartProductDetails = this.detail.isMultiPartProduct ?
        (this.detail.productDetails || []).concat(this.detail.multiPartInfo.reduce(function (prev, item) {
          prev.push(...(item.attributeList || []))
          return prev
        }, [])) :
        (this.detail.productDetails || [])
      const findFabric = multiPartProductDetails.find(i => i.attr_name_en === 'Fabric')
      const findFabricIt = findFabric && findFabric['attr_value_en']
      if(findFabricIt) {
        return this.fabric.find(i => i.key === findFabricIt)
      }
      const findStretch = multiPartProductDetails.find(i => i.attr_name_en === 'Stretch')
      const findStretchIt = findStretch && findStretch['attr_value_en']
      if(findStretchIt ) {
        return this.fabric.find(i => i.key === findStretchIt)
      }
      return false
    },
    sizeInfo () {
      return (this.unitType == 'cm' ? this.sizeInfoDes.sizeInfo : this.sizeInfoDes.sizeInfoInch) || []
    },
    mode () {
      return this.sizeInfoDes.basicAttribute?.base_code_show_mode || 0
    },
    multiPartList () {
      return this.sizeInfoMode[this.unitType]['normal'].reduce((res, item) => {
        if (item.name && item.list.some(_ => Object.keys(_).length > 0)) {
          res.push(item)
        }
        return res
      }, [])
    },
    sizeInfoMode () {
      const showNomal = [0, 2, 3]
      const showBase = [0, 1, 3]
      let {
        normalCm,
        normalInch,
        baseCm,
        baseInch,
        baseOther,
        sizeInfoDesAttrName
      } = formatSizeInfo(this.skcSaleAttr, this.sizeInfoDes)
      let baseNoData = false
      if (baseOther[0].list.length > 0) {
        const len = Math.max(baseOther[0].list.length, baseCm[0].list.length)
        for (let i = 0; i < len; i++) {
          baseCm[0].list[i] = Object.assign(baseCm[0].list[i] || {}, baseOther[0].list[i] || {})
          baseInch[0].list[i] = Object.assign(baseInch[0].list[i] || {}, baseOther[0].list[i] || {})
        }
      }
      if (baseCm[0].list.length == 0 && baseInch[0].list.length == 0) {
        if (this.mode == 3) {
          baseInch = normalInch
          baseCm = normalCm
        }
        baseNoData = true
      }
      return {
        inch: {
          normal: showNomal.includes(this.mode) ? normalInch : [],
          base: showBase.includes(this.mode) ? baseInch : []
        },
        cm: {
          normal: showNomal.includes(this.mode) ? normalCm : [],
          base: showBase.includes(this.mode) ? baseCm : []
        },
        baseNoData,
        sizeInfoDesAttrName
      }
    },
    tableTitle () {
      if (this.mode == 1 || this.mode == 2) {
        return this.mode == 2 ? this.language.SHEIN_KEY_PWA_17563 : this.language.SHEIN_KEY_PWA_17561
      }
      if ((this.mode == 0 || this.mode == 3) && this.sizeInfoMode.baseNoData) {
        return this.language.SHEIN_KEY_PWA_17563
      }
      return ''
    },
    currMultiPart () {
      return this.selectMultiPart || this.multiPartList[0]
    },
    currSizeInfo () {
      let list = (this.sizeInfoDes.multiPartFlag && this.tableStatus == 'normal') ?
        ((this.sizeInfoMode[this.unitType][this.tableStatus].find(_ => _?.code === this.currMultiPart?.code) || { list: [] }).list) :
        this.sizeInfoMode[this.unitType][this.tableStatus][0].list
      let result = []
      if (this.tableStatus === 'normal') {
        result = list
      } else {
        let baseAttrSort = this.sizeInfoDes.basicAttribute?.base_size_sort || null
        result = baseAttrSort && list.sort((a, b) => baseAttrSort[a.size] - baseAttrSort[b.size])
      }
      return result
    },
    curNormalSizeInfo() {
      return this.sizeInfoDes.multiPartFlag ?
        ((this.sizeInfoMode[this.unitType]['normal'].find(_ => _?.code === this.currMultiPart?.code) || { list: [] }).list) :
        this.sizeInfoMode[this.unitType]['normal'][0]?.list || []
    },
    headName () {
      return this.sizeInfoMode.sizeInfoDesAttrName || this.language.SHEIN_KEY_PWA_15701
    },
    tableHeadBase() {      
      let sortKeys = []
      if (this.tableStatus === 'normal') {
        let dimensionAttrInfo = []
        if (this.sizeInfoDes?.multiPartFlag === 1) {
          dimensionAttrInfo = this.sizeInfoDes?.multiPartInfo?.find(item => item.multiPartCode === this.currMultiPart?.code)?.dimensionAttrInfo || []
        } else {
          dimensionAttrInfo = this.sizeInfoDes?.dimensionAttrInfo ? [...this.sizeInfoDes.dimensionAttrInfo] : []
        }
        sortKeys = dimensionAttrInfo.sort((a, b) => {
          return a.sort - b.sort
        }).map((item) => { 
          return item.attr_name 
        }) 
      } else {
        let baseSizeSortMap =  cloneDeep(this.sizeInfoDes?.basicAttribute?.base_attr_sort || {})
        sortKeys = Object.keys(baseSizeSortMap).sort((a, b) => baseSizeSortMap[a] - baseSizeSortMap[b])
      }
      const result = sortKeys?.map((item) => { 
        return { value: item }
      }) || []

      result.unshift({
        value: this.headName
      })

      if (this.hasLocalColumn) {
        result.splice(this.sizeGuidPlanA ? 0 : 1, 0, {
          value: this.selectLocalsize,
        })
      }
      return result
    },
    firstTableHeadBase(){
      const result = cloneDeep(this.tableHeadBase)?.splice(0,1)|| []
      return result
    },
    remainTableHeadBase(){
      const result = cloneDeep(this.tableHeadBase)
      return result?.splice(1) || []
    },
    multiLocalSize() {
      return this.localsize.size_rule_list || {}
    },
    // 部分基码图size字段没数据
    hasSizeColumn() {
      const sizeInfo = this.sizeInfo
      if (sizeInfo && sizeInfo.length) {
        return !!(sizeInfo[0].attr_value_name || sizeInfo[0].size)
      }
      return true
    },
    hasLocalColumn() {
      const sizeInfoDes = this.curNormalSizeInfo
      if (sizeInfoDes) {
        for (const i in sizeInfoDes) {
          const item = sizeInfoDes[i]
          if (this.selectLocalsize && this.multiLocalSize[this.selectLocalsize] && this.multiLocalSize[this.selectLocalsize].length) {
            const find = this.multiLocalSize[this.selectLocalsize].find(i=>i.name === (item.attr_value_name || item.size))
            if (find) {
              return true
            }
          }
        }
      }
      return false
    },
    hasOneLocalColumn () {
      for (const k in this.multiLocalSize) {
        if (this.multiLocalSize[k]?.length) {
          for (let i = 0; i < this.multiLocalSize[k].length; i++) {
            const f = this.curNormalSizeInfo.find(_ => (_.attr_value_name || _.size) === this.multiLocalSize[k][i].name)
            if (f) {
              return true
            }
          }
        }
      }
      return false
    },
    tableBodyBase () {
      const { currSizeInfo } = this
      const result = []
      const nullMap = {}
      for (const i in (currSizeInfo || [])) {
        const item = currSizeInfo[i]
        let baseSizeItem = []
        let find
        if (this.hasLocalColumn) {
          find = this.multiLocalSize[this.selectLocalsize].find(i=>i.name === (item.attr_value_name || item.size)) || {}
          if (find.name) {
            if(this.sizeGuidPlanA){
              baseSizeItem.unshift(find.name) // (${find.correspond})
              baseSizeItem.unshift(find.correspond)
            }else{
              baseSizeItem.push(find.name) // (${find.correspond})
              baseSizeItem.push(find.correspond)
            }   
          } else {
            baseSizeItem.push(item.attr_value_name || item.size)
            baseSizeItem.push('/')
          }
        } else {
          baseSizeItem.push(item.attr_value_name || item.size)
        }
        for (const h in this.tableHeadBase) {
          if (!find && h == 0 || find && h <= 1) {
            if (!nullMap[h]) {
              nullMap[h] = true
            }
            continue
          }
          const headVal = this.tableHeadBase[h].value
          if (item[headVal]) {
            const v = item[headVal].replace(/(cm|inch)/g, '').trim()
            if (!nullMap[h] && v !== '/') {
              nullMap[h] = true
            }
            baseSizeItem.push(v || '/')
          }

        }
        find = null
        result.push(baseSizeItem)
      }
      return {
        nullMap,
        result,
      }
    },
    sizeTipsText() {
      return '*' + (this.tableStatus == 'normal' ? 
        this.unitType === 'cm' ? this.language.SHEIN_KEY_PWA_17562 : this.language.SHEIN_KEY_PWA_33425
        : this.language.SHEIN_KEY_PWA_17565)
    },
    tableContainerWidth () {
      if (!this.isShow || !this.tableBodyBase?.result) return
      if (this.sizeGuidPlanA) return '100%'
      let tableData = this.tableBodyBase.result
      let itemWidth = 1.86
      let tableWidth = tableData[0]?.length * itemWidth
      let result = ''
      tableData.forEach(item => {
        if (item[0]?.length > 8) {
          result = itemWidth + tableWidth + 'rem'
        }
        if (item[0]?.length > 40) {
          result = itemWidth * 2 + tableWidth + 'rem'
        }
      })
      return result
    }
  },
  watch: {
    modelValue (newVal) {
      this.isShow = newVal
      !newVal && this.showLocalSize && (this.localSizeSelectHandle(false))
    },
    isShow (newVal) {
      this.$emit('update:model-value', newVal)
      let sizeUnit = localStorage.getItem('selectedUnit')
      this.unitType = sizeUnit || this.unitType
      // try {
      //   this.changePageStatus && this.changePageStatus({
      //     showSizeGuide: false,
      //     sizeGuideMultiDrawer: false
      //   })
      // } catch(e) {
      //   console.log()
      // }
    },
    country: {
      handler(val) {
        this.selectLocalsize = val
      },
      immediate: true
    },
    goodsId() {
      this.initMode()
    },
    mode: {
      immediate: true,
      handler () {
        this.initMode()
      }
    },
    showFitType(){
      this.updateWidth()
    },
    showFabric(){
      this.updateWidth()
    }
  },
  mounted () {
    if (this.showSizeGuideDrawer) {
      this.isShow = true
    }
    this.updateWidth()
  },
  methods: {
    /**
     * 根据 sizeInfo 生成 尺码排序对照表
     */
    genSizeSortMap(sizeInfo) {
      let sizeSortMap = {}
      sizeInfo.forEach((item, index) => {
        sizeSortMap[item.attr_value_name_en] = index
      })
      return sizeSortMap
    },
    handleShowModel (val) {
      // this.showDetail = !val
      // if(this.sizeGuidPlanA){
      //   this.showModel = val
      // }
      this.showModel = val
    },
    handleShowTable (val){
      this.showDetail = val
    },
    initMode() {
      this.tableStatus = getPrioritySize(this.skcSaleAttr, this.sizeInfoDes, this.sizepriority)
      this.initTableStatus = this.tableStatus
    },
    isChildrenShoesSML (sizeinfo) {
      return sizeinfo.filter((value) => {
        return value.age && value['Size(US)'] && value['Size(EUR)']
      }).length == 0
    },
    changeUnit (val) {
      this.unitType = val
      localStorage.setItem('selectedUnit', val)
      this.$emit('changeSizeUnit', val)
    },
    changeTableStatus (val) {
      this.tableStatus = val
    },
    filterUnit (value) {
      if (!value) return ''
      const v = value.replace(/(cm|inch)/gi, '')
      return (/^-?\d*\.\d{3,}$/.test(v)) ? parseFloat(v).toFixed(2) : v
    },
    localSizeSelectHandle(status) {
      if (status) {
        SelectLocalSize.open({
          id: this.detail?.goods_id, 
          value: this.selectLocalsize,
          localSize: this.localsize,
          options: {
            config: {
              appendToBody: this.appendToBody,
              isNewSizeLocal: this.isNewSizeLocal
            },
            callbacks: {
              change: country => {
                this.$emit('changeLocalSize', country)
              },
              opened: () => {
                this.showLocalSize = true
              },
              closed: () => {
                this.showLocalSize = false
              }
            }
          }
        })
      } else {
        SelectLocalSize.close()
      }
    },
    handleScroll(event) {
      console.log(11111)
      this.showShadow = event.target.scrollLeft > 0
    },
    updateWidth() {
      nextTick(() => {
        if(this.$refs.div1 && this.$refs.div2) {
          // 重置宽度以确保offsetWidth反映自然内容宽度
          this.$refs.div1.style.width = 'auto'
          this.$refs.div2.style.width = 'auto'

          const div1Width = this.$refs.div1.offsetWidth
          const div2Width = this.$refs.div2.offsetWidth
          const maxWidth = Math.max(div1Width, div2Width)
          // 增加3像素，防止计算偏差
          const width = maxWidth + 3
          // 现在设置最大宽度到两个元素
          this.$refs.div1.style.width = `${width}px`
          this.$refs.div2.style.width = `${width}px`
        }else{
          this.$refs.div1 && (this.$refs.div1.style.width = 'auto')
          this.$refs.div2 && (this.$refs.div2.style.width = 'auto')
        }
      })
    }
  },
})
</script>

<style lang="less">
.sizeChart-drawer {
  &__header {
    position: relative;
    width: 100%;
    height: 1.17rem;
    line-height: 1.17rem;
    color: #333;
    font-weight: bold;
    .font-dpr(34px);
    text-align: center;
    /* rw:begin */
    font-family: 'Adieu';
    [class*="iconfont"] {
      position: absolute;
      .left(0);
      top: 0;
      width: 1.17rem;
      height: 1.17rem;
      font-weight: normal;
    }
    .sui_icon_nav_back_24px {
      .font-dpr(48px);
    }
  }
  &__content {
    border-bottom: 1.17rem solid #f6f6f6;
    min-height: calc(100% - 1.17rem);
    background: #f6f6f6;
  }
  .sui_icon_more_down_16px {
    color: #959595;

  }
  .sui_icon_more_down_16px.reverse {
    transform: rotate(180deg);
    transition: transform 0.3s;
  }
}
.sizeGuidPlanA-drawer {
  .fit-fabric__title {
    padding-top: 0;
    max-width: 2.6667rem;
    font-size: .3733rem;
    margin-bottom: 0.2rem;
    word-break: break-all;
  }
  .fit-fabric__planA{
    gap: 0.5333rem;
    align-items: flex-end;
  }
  .fit-fabric {
    &__dec {
      display: flex;
      border-top:none;
      border-bottom: 2px solid #E5E5E5;
      justify-content: space-between;
      margin-top: 0.32rem;
      padding-bottom: .2133rem;
      margin-bottom: 0.4rem;
      color: #222;
      div {
        position: relative;
        padding-top: 0;
        max-width: 2rem;
      }
    }

    &__pos {
      position: absolute;
      display: block;
      background: #E5E5E5;
      width: 2px;
      height: 6px;
      bottom: -0.32rem;
      top:auto;
    }
  }
  .fit-fabric__select{
    background: #000;
    height: 0.16rem;
    width: 0.5333rem;
    margin-top: -0.08rem;
  }
  .fit-fabric__icon{
    display: none;
  }
  .sg-table-base{
    border:none;
  }
  .sg-table-base td {
    border: 1px solid #E5E5E5;
  }
  .sui-drawer-local {
    border-radius: 0.4rem 0.4rem 0 0;
  }
  .measure-guide{
    margin-top: 0;
  }
  .size-table-outer{
    margin-bottom: 0;
  }
  .measure-guide__header{
    color: var(---sui_color_black, #000);
    font-size: 0.3733rem;
    font-style: normal;
    font-weight: 500;
  }
  .measure-guide__footer{
    background: #FFF;
    padding:0 0.32rem 0px 0.32rem;
  }
  .size-table-outer{
    .sg-table__title{
      justify-content: flex-start;
      padding-left: 0.32rem;
      gap: 0.64rem;
    }
  }
  .sg-table-base tr:first-child  td {
    height: 1.0133rem;
    line-height: unset;
    padding: .16rem .2133rem;
  }

  .sg-table_tr{
    .sg-table_td{
      line-height: unset;
      padding: .4267rem .2133rem;
      white-space: nowrap;
    }
  }
  .sg-table-side{
    padding: 0 0 .2133rem 0;
    margin-left: 0.32rem;
    margin-right: 0.32rem;
    width: calc(100% - 0.64rem);
    overflow-x: visible;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  .measure-wrap{
    p {
      font-size: 0.32rem;
    }
  }
  .sg-table-side{
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
  // 尺码表样式
  .sg-table-box{
    overflow-y: auto;
    border-left: 1px solid #e5e5e5;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
  .sg-table-content {
    min-width: 100%;
    tr td:first-child, tr th:first-child {
      position: sticky;
      left: 0;
      border-left: none !important;
    }
    thead th {
      overflow-wrap: break-word;
      white-space: normal;
    }
    tbody td {
      white-space: nowrap;
    }
  }
  .sg-table_td-shadow {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: -.4267rem;
      bottom: 0;
      width: .4267rem;
      height: 100%;
      background: linear-gradient(270deg, rgba(0, 0, 0, 0.00)0%, rgba(0, 0, 0, 0.05)100%);
    }
  }
}
.c-drawer-local {
  /*rw:begin*/
    border-radius: 0.4rem 0.4rem 0 0;
}
.common-detail {
  display: inline-flex;
  width: 100%;
  margin-bottom: .22rem;
  padding: 0 0.32rem;
  height: 92/75rem;
  background-color: @sui_color_white;
  justify-content: space-between;
  align-items: center;
  &__left {
    font-weight: 500;
    .font-dpr(28px);
    line-height: 32/75rem;
    color: #333;
  }
  &__right {
    display: inline-flex;
    align-items: center;
  }
}
.sg-size-local {
  display: inline-flex;
  align-items: center;
  border: 1px solid #E5E5E5;
  color: @sui_color_gray_dark1;
  background-color: #F6F6F6;
  border-radius: .6933rem;
  height: .6933rem;
  padding: 0 .32rem;
  span {
    .font-dpr(24px);
  }
  .suiiconfont {
      vertical-align: top;
    }
}
.sg-size-newTitle {
  margin-left: 24/75rem;
  .sg-title-ctn {
    width: 1.8133rem;
    height: .6933rem;
    padding: .08rem;
    .flexbox();
    align-items: center;
    justify-content: center;
    border: 1px solid #E5E5E5;
    border-radius: .4267rem;
    background-color: #F6F6F6;

    /*rw:begin*/
    border: none;
  }

  li {
    width: .96rem;
    height: .5333rem;
    line-height: .5333rem;
    text-align: center;
    color: #222;

    /*rw:begin*/
    // border: 1px solid #ccc;

    &.title-active {
      background: #222;
      color: #fff;
      border-radius: .5867rem;
      /*rw:begin*/
      // color: #FF9999;
      // background: #FFEFED;
      // border-color: #FF9999;
      background: @sui_color_main;
    }
  }
}
.fit-fabric {
  background: #FFFFFF;
  padding:0 0.32rem;
  margin-bottom: .22rem;
  &__planA{
    display: flex;
    gap: 20px;
    .fit-fabric__dec{
      flex: 1;
      
    }
  }
  .fit-fabric__left {
    text-align: left/*rtl:ignore*/;
  }
  .fit-fabric__center {
    text-align: center;
  }
  .fit-fabric__right {
    text-align: right/*rtl:ignore*/;
  }
  &__title {
    font-weight: 700;
    .font-dpr(28px);
    color: #333;
    padding-top: 0.32rem;
    &:last-child {
      padding-top: 0.666rem;
    }
  }

  &__dec {
    display: flex;
    border-top: 1px solid #CCCCCC;
    justify-content: space-between;
    margin-top: 0.32rem;
    padding-bottom: 0.32rem;
    color: #222;
    div {
      position: relative;
      padding-top: 0.4rem;
      max-width: 2rem;
    }
  }

  &__pos {
    position: absolute;
    display: block;
    background: #CCCCCC;
    width: 1px;
    height: 6px;
    top: 0px;
  }

  &__icon {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 6px 0 6px;
    border-color: #222 transparent transparent transparent;
  }

  &__posR {
    .right(0);

    .fit-fabric__icon {
      .left(-12px);
    }
  }

  &__posC {
    transform: translateX(-50%);
    left: 50%;

    .fit-fabric__icon {
      .left(-6px);
    }
  }

  &__select {
    background: none;
  }
}

.size-table-outer {
  background-color: #fff;
  // margin-bottom: .22rem;
  .sg-table__title {
    background: white;
    height: 1.173333rem;
    .flexbox;
    justify-content: space-around;
    align-items: center;
    .font-dpr(28px);
    font-weight: bold;
    color: #767676;
  }
  .sg-table__part {
    margin: .32rem;
    overflow-x: scroll;
    li {
      display: inline-block;
      .border-dpr(border, 2px, #222);
      .border-dpr(border-right, 0px, #222);
      padding: 0 .32rem;
      line-height: .72rem;
      font-size: 12px;
      &:last-of-type {
        .border-dpr(border-right, 2px, #222);
      }
    }
    .part-active {
      background: #222;
      color: white;
    }
  }
  .sg-table__part-wrap {
    white-space: nowrap;
    font-size: 0;
  }
  .sg-table__title-s {
    position: relative;
    &.active {
      color: #222;
      &::after {
        content: "";
        height: .08rem;
        position: absolute;
        bottom: -.066667rem;
        left: 0;
        right: 0;
        background: #222;
        /*rw:begin*/
        background: #FF696E;
      }
    }
    &.sortAfter {
      order: 1;
    }
  }
  .sg-table-t {
    margin: 0 .32rem .15rem;
    padding-top: .32rem;
    line-height: .51rem;
    font-weight: bold;
    .font-dpr(28px);
  }

  .sg-table-new {
    .font-dpr(24px);
    color: #fff;
    display: inline-block;
    background: #5EBD66;
    padding: 0 0.1rem;
    line-height: 1.4;
    font-weight: 100;
  }

  .sg-table-notice {
    color: #999;
    margin: 0 0.32rem;
    padding: 0 0 .32rem;
    font-size: 0.32rem;
  }

  .sg-table-icon {
    width: 0;
    height: 0;
    border: 4px solid transparent;
    .border-r(4px, #5EBD66);
    display: inline-block;
  }

  .sg-table-tip {
    color: #FAAD14;
    .margin-l(0.32rem);
    margin-bottom: 0.15rem;
  }

  .sg-table-localsize {
    margin: 0 .32rem .32rem;
    box-sizing: content-box;
    height: .75rem;
    line-height: .75rem;

    li {
      display: inline-block;
      .margin-r(.64rem);
      font-weight: bold;
      .font-dpr(24px);
      color: #999;
    }

    .title-active {
      border-bottom: 2px solid #222;
      color: #222;
    }
  }

  .common-sizemeasure__sizeChart-size {
    color: #222222;
    cursor: pointer;
    padding: 0 0.32rem;
    padding-top: 0.18rem;

    span {
      width: 1.49rem;
      height: 0.74rem;
      border: 1px solid #E5E5E5;
      display: inline-block;
      margin-bottom: 0.32rem;
      .margin-r(0.32rem);
      text-align: center;
      line-height: 0.74rem;
      border-radius: 0.68rem;
    }

    .common-sizemeasure__sizeChart-sizeSelect {
      border-color: #222222;

      /*rw:begin*/
      border-color: #FF696E;
      color: #FF696E;
    }
  }

  .common-sizemeasure__sizeChart-chart {
    background: #F6F6F6;
    padding-top: 0.32rem;
    margin: 0 0.32rem;
    margin-bottom: 0.32rem;
  }

  .common-sizemeasure__sizeChart-local {
    color: #222;
    font-weight: 700;
    .font-dpr(28px);
    margin-bottom: 0.32rem;
    .margin-l(0.32rem);
  }

  .common-sizemeasure__sizeChart-data {
    min-width: 4rem;
    display: inline-block;
    color: #222;
    margin: 0 0.32rem;
    margin-bottom: 0.32rem;
  }
}
.sg-table-side {
  box-sizing: border-box;
  width: 100%;
  padding: 0 .2133rem .32rem;
  overflow-y: visible !important;/* stylelint-disable-line declaration-no-important */
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .sg-table {
    background: #fff;
    .border-dpr(border, 2px, #e5e5e5);
    text-align: center;
    min-width: 100%;
    // 尺码表样式
    &-content {
      border-collapse: separate;
      text-align: center;
      .font-dpr(24px);
      td, th {
        padding: .1067rem;
        min-width: 1.8667rem;
        padding: 16px 8px;
        line-height: 1;
        border-right: 1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        background: #fff;
      }
      tr td:first-child, tr th:first-child{
        font-weight: 700;
        background: #f6f6f6;
        border-left: 1px solid #e5e5e5;
      }
      thead th {
        font-weight: 700;
        padding: 8px 8px;
        background: #f6f6f6;
        border-top: 1px solid #e5e5e5;
        height: 1.0133rem;
      }
    }
  }
}
.measure-guide {
  padding: 0 .32rem .5rem;
  margin-bottom: .22rem;
  padding-bottom: 0;
  background: #fff;
  // margin-top: 0.16rem;

  &__header {
    border-top: 1px solid #E5E5E5;
    height: 1.16rem;
    line-height: 1.16rem;
    font-size: 0.32rem;
    font-weight: 400;
    color: #222222;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  [class*="iconfont"] {
    transition: .3s all ease;
  }
  .iconfont-active {
    transform: rotateZ(180deg);
  }
  &__pic {
    display: flex;
    padding-bottom: .32rem;
    .measure-pic0 {
      flex: 1;
      img {
        width: 100%;
      }
    }

    .measure-pic-guide {
      width: 5.333333rem;
      .margin-r(.32rem);
      padding-bottom: 0.32rem;
    }
    .measure-wrap {
      margin-bottom: 0.32rem;
      >h6 {
        font-weight: 700;
        margin-bottom: .16rem;
        text-transform: capitalize;
      }
      >p {
        word-wrap: break-word;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.c-drawer .address-mshe-mask{
    position: fixed;
    top: 0;
    right: 0/*rtl:ignore*/;
    bottom: 0;
    left: 0/*rtl:ignore*/;
    background-color: rgba(0,0,0,0.6);
    z-index: 999; /* stylelint-disable-line declaration-property-value-blacklist */
    transform: translate3d(0,0,999px);
}
</style>
