import { createApp } from 'vue'
let compInstance = null
export default {
  open: (config) => {
    if (compInstance) {
      compInstance.open(config)
      return compInstance
    }
    return new Promise((resolve) => {
      import(/* webpackChunkName: "select_local_size" */ './main.vue').then((components) => {
        const selectLocalSize = components.default

        const app = createApp(selectLocalSize)
        const appDom = document.createElement('div')
        const vm = app.mount(appDom)
        document.body.appendChild(vm.$el)
        const comp = vm
        comp.open(config)
        compInstance = comp
        resolve(compInstance)
      })
    })
  },
  close: () => {
    if (compInstance && compInstance.close) {
      compInstance.close()
    }
  },
}
